<template>
	<div>
		<div class="row form-group">
			<label for="proveedor" class="col-form-label col-sm-2">Proveedor</label>
			<div class="col-sm-4">
				<input v-model="factura.proveedor_nombre" type="text" name="proveedor" id="proveedor" class="form-control" readonly>
			</div>
			<label for="proveedor" class="col-form-label col-sm-2">Método Pago</label>
			<div class="col-sm-4">
				<input v-model="obtener_metodo_pago" type="text" name="metodo_pago" id="metodo_pago" class="form-control" readonly>
			</div>
		</div>
		<div class="row form-group">
			<label for="total" class="col-form-label col-sm-2">Total</label>
			<div class="col-sm-4">
				<input v-model="total_procesada" type="text" name="total" id="total" class="form-control" readonly>
			</div>
			<label for="subtotal" class="col-form-label col-sm-2">Subtotal</label>
			<div class="col-sm-4">
				<input v-model="subtotal_procesada" type="text" name="subtotal" id="subtotal" class="form-control" readonly>
			</div>					
		</div>
		<div class="row form-group">
			<label for="total" class="col-form-label col-sm-2">Total Impuesto Retenidos</label>
			<div class="col-sm-4">
				<input v-model="total_impuestos_retenidos" type="text" name="total_impuestos_retenidos" id="total_impuestos_retenidos" class="form-control" readonly>
			</div>
			<label for="subtotal" class="col-form-label col-sm-2">Total Impuestos Trasladados</label>
			<div class="col-sm-4">
				<input v-model="total_impuestos_trasladados" type="text" name="total_impuestos_trasladados" id="total_impuestos_trasladados" class="form-control" readonly>
			</div>					
		</div>
		<div class="row form-group">
			<label for="fecha_emision" class="col-form-label col-sm-2">Fecha emisión</label>
			<div class="col-sm-4">
				<input v-model="factura.fecha_emision" type="text" name="fecha_emision" id="fecha_emision" class="form-control" readonly>
			</div>
			<label for="created_at" class="col-form-label col-sm-2">Fecha enviado</label>
			<div class="col-sm-4">
				<input v-model="factura.created_at" type="text" name="created_at" id="created_at" class="form-control" readonly>
			</div>
		</div>
		<div class="row form-group">
			<label class="col-form-label col-sm-2">UUID</label>
			<div class="col-sm-4">
				<div class="form-control">{{ factura.uuid }}</div>
			</div>
		</div>
		<div class="row form-group">
			<label for="conceptos" class="col-form-label col-sm-2">Conceptos</label>
			<div class="col-sm-10">
				<table class="factura_tables">
					<thead>
						<th>ClaveProdServ</th>
						<th>ClaveUnidad</th>
						<th>Descripción</th>
						<th>Cantidad</th>
						<th>Valor unitario</th>
						<th>Importe</th>
					</thead>
					<tbody>
						<tr v-for="concepto in factura.conceptos">
							<td>{{ concepto.clave_prod_serv }}</td>
							<td>{{ concepto.clave_unidad }}</td>
							<td>{{ concepto.descripcion }}</td>
							<td>{{ concepto.cantidad }}</td>
							<td>${{ $helper.moneyFormat(concepto.valor_unitario) }}</td>
							<td>${{ $helper.moneyFormat(concepto.importe) }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="row form-group">
			<label for="impuestos" class="col-form-label col-sm-2">Impuestos</label>
			<div class="col-sm-10">
				<table class="factura_tables">
					<thead>
						<th>Tipo</th>
						<th>Impuesto</th>
						<th>Importe</th>
						<th>TipoFactor</th>
						<th>TasaOCuota</th>
					</thead>
					<tbody>
						<tr v-for="impuesto in factura.impuestos">
							<td>{{ impuesto.tipo }}</td>
							<td>{{ impuesto.impuesto }}</td>
							<td>{{ '$'+$helper.moneyFormat(impuesto.importe) }}</td>
							<td>{{ impuesto.tipo_factor }}</td>
							<td>{{ impuesto.tasa_o_cuota }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="row form-group">
			<label for="pagos" class="col-form-label col-sm-2">Pagos</label>
			<div class="col-sm-10">
				<table class="factura_tables">
					<thead>
						<th>Banco</th>
						<th>Cuenta</th>
						<th>Monto</th>
						<th>Fecha de pago</th>
						<th>Complemento de pago</th>
						<th>Fecha de subida</th>
					</thead>
					<tbody>
						<tr v-for="pago in factura.pagos">
							<td>{{ pago.banco }}</td>
							<td>{{ pago.cuenta }}</td>
							<td>${{ $helper.moneyFormat(pago.monto) }}</td>
							<td>{{ pago.created_at }}</td>
							<td>{{ pago.docto_relacionado ? pago.docto_relacionado.complemento.uuid : 'Sin comprobante de pago' }}</td>
							<td>{{ pago.docto_relacionado ? pago.docto_relacionado.complemento.created_at : 'Sin comprobante de pago'}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="row form-group">
			<label for="observaciones" class="col-form-label col-sm-2">Observaciones</label>
			<div class="col-sm-10 observaciones">
				<table class="observaciones factura_tables">
					<thead>
						<th style="width: 10%">Usuario</th>
						<th style="width: ">Observación</th>
						<th style="width: 10%">Fecha</th>
					</thead>
					<tbody v-if="factura.observaciones.length > 0">
						<tr v-for="observacion in factura.observaciones">
							<td>{{ observacion.nombre }}</td>
							<td>{{ observacion.observaciones }}</td>
							<td>{{ observacion.created_at | moment('YY-MM-DD HH:mm') }}</td>
						</tr>
					</tbody>
					<tbody v-else>
						<tr>
							<td colspan="3">No hay observaciones</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div v-if="factura.estatus < 6" class="row form-group align-items-center">
			<label for="observacion" class="col-form-label col-sm-2">Agregar observaciones</label>
			<div class="col-sm-8">
				<input v-model="observacion" type="text" name="observacion" id="observacion" class="form-control">
			</div>
			<div class="col-sm-2 text-center">
				<button class="btn secondary-btn agregar_observacion_btn" @click="agregar_observacion" :disabled="factura.estatus == 0">Agregar</button>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		props: {
			factura: {
				type: Object
				,default: () => ({
					proveedor_nombre: null
					,uuid: null
					,rfc_emisor: null
					,rfc_receptor: null
					,serie: null
					,folio: null
					,fecha_emision: null
					,created_at: null
					,conceptos: []
					,pagos: null
					,observaciones: []
				})
			}
		}
		,data: function() {
			return {
				observacion: null
			}
		}
		,methods: {
			agregar_observacion: function() {
				this.$emit('observaciones', this.observacion)
				this.observacion = null;
			}
		}
		,computed: {
			total_procesada: function() {
				return '$'+this.$helper.moneyFormat(this.factura.total)
			}
			,subtotal_procesada: function() {
				return '$'+this.$helper.moneyFormat(this.factura.subtotal)
			}
			,obtener_metodo_pago: function() {
				let metodo = '';

				switch(this.factura.metodo_pago) {
					case 'PUE':
						metodo = 'Pago en una sola exhibición (PUE)';
						break;
					case 'PPD':
						metodo = 'Pago en parcialidades o diferido (PPD)';
						break;
				}

				return metodo;
			}
			,total_impuestos_retenidos: function() {
				if (this.factura.total_impuestos_retenidos)
					return '$'+this.$helper.moneyFormat(this.factura.total_impuestos_retenidos)
				else
					return null;
			}
			,total_impuestos_trasladados: function() {
				if (this.factura.total_impuestos_retenidos)
					return '$'+this.$helper.moneyFormat(this.factura.total_impuestos_trasladados)
				else
					return null;
			}
		}
	}
</script>

<style lang="scss">
	table.factura_tables {
		width: 100%;

		thead {
			background-color: #000 !important;

			th {
				padding: 3px 10px !important;
			}
		}

		tbody {
			tr:nth-child(2n) {
				background-color: #E6E6E6 !important;
			}
		}
	}
	.observaciones {
		max-height: 150px;
		overflow: auto;
		
		.row {
			margin-right: 3px;
			padding: 3px;

			span.nombre {
				font-weight: bold;
			}

			span.fecha {
				font-size: 0.8em;
			}
		}

		.row:nth-child(2n+0) {
			background-color: #E6E6E6;
		}
	}
</style>